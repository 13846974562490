import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { PendingChangesGuard } from 'weavix-shared/guards/pending-changes.guard';
import { AnalyticsService, StAction, StObject } from 'weavix-shared/services/analytics.service';
import { Modal, ModalActionType } from 'weavix-shared/services/modal.service';
import { forkJoin } from 'rxjs';
import { Folder, FolderPermission, FolderType, PeopleTargets } from '@weavix/models/src/folder/folder';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { AlertService, ServiceError } from 'weavix-shared/services/alert.service';
import { CompanyService } from 'weavix-shared/services/company.service';
import { CraftService } from 'weavix-shared/services/craft.service';
import { FolderService } from 'weavix-shared/services/folder.service';
import { PersonService } from 'weavix-shared/services/person.service';
import { TagService } from 'weavix-shared/services/tag.service';
import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { Chip, ChipListComponent } from '../../chip-list/chip-list.component';
import { DropdownItem } from '../../dropdown/dropdown.model';
import { ModalComponent } from 'components/modal/modal.component';
import { CommonModule } from '@angular/common';
import { InputComponent } from 'components/input/input.component';
import { DropdownComponent } from 'components/dropdown/dropdown.component';
import { FormButtonsComponent } from 'components/form-buttons/form-buttons.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from 'components/icon/icon.component';

interface FolderEditorModalForm {
    id: FormControl<string>;
    name: FormControl<string>;
    permissions: FormArray<FormGroup<GrantPermissionsByForm>>;
}

interface GrantPermissionsByForm {
    target: FormControl<PeopleTargets | null>;
    action: FormControl<'generics.edit' | 'generics.read' | 'generics.submit' | null>;
    values: FormControl<string[] | null>;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-folder-editor-modal',
    templateUrl: './folder-editor-modal.component.html',
    styleUrls: ['./folder-editor-modal.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,

        ChipListComponent,
        DropdownComponent,
        FormButtonsComponent,
        IconComponent,
        InputComponent,
        ModalComponent,
    ],
})
export class FolderEditorModalComponent implements OnInit, OnChanges {
    @Input() open = false;
    @Input() folderId: string = null;
    @Input() parentFolderId: string = null;
    @Input() folderType: FolderType;
    @Input() viewPermissionAction: PermissionAction;
    @Input() editPermissionAction: PermissionAction;
    @Input() submitPermissionAction: PermissionAction;
    @Input() hidePermissions: boolean;
    @Output() closed = new EventEmitter<ModalActionType>();

    folderEditorModal: Modal;

    form: FormGroup<FolderEditorModalForm>;

    peopleTargets: DropdownItem[] = [];
    actionPermissions: DropdownItem[] = [];

    crafts: Chip[] = [];
    tags: Chip[] = [];
    companies: Chip[] = [];
    people: Chip[] = [];

    css = css;

    addTagFn = async (tag: string): Promise<Chip> => {
        const newTag = await this.tagService.add(this, { name: tag });
        return { id: newTag.id, name: newTag.name };
    };

    constructor(
        private cdr: ChangeDetectorRef,
        private pendingChangesGuard: PendingChangesGuard,
        private alertsService: AlertService,
        private craftService: CraftService,
        private tagService: TagService,
        private companyService: CompanyService,
        private personService: PersonService,
        private folderService: FolderService,
    ) { }

    async ngOnInit() {
        this.peopleTargets = Object.keys(PeopleTargets).map(t => ({ data: t, label: t, key: PeopleTargets[t] }));
        this.actionPermissions = [
            { key: 'generics.edit', label: 'generics.edit', data: this.editPermissionAction },
            { key: 'generics.read', label: 'generics.read', data: this.viewPermissionAction },
        ];
        if (this.folderType === FolderType.WorkForms) this.actionPermissions.push(
            { key: 'generics.submit', label: 'generics.submit', data: this.submitPermissionAction },
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.open?.currentValue) {
            this.modalOpen();
        } else {
            this.modalClose();
        }
    }

    canDeactivate() { return this.form.pristine; }

    public get permissions(): FormArray<FormGroup<GrantPermissionsByForm>> {
        return this.form.controls.permissions;
    }

    public isPermissionModified(idx: number): boolean {
        const targetControl = this.permissions.controls[idx].controls.target;
        const actionControl = this.permissions.controls[idx].controls.action;
        return !!targetControl.value || !!actionControl.value;
    }

    public isPermissionValid(idx: number): boolean {
        const targetControl = this.permissions.controls[idx].controls.target;
        const actionControl = this.permissions.controls[idx].controls.action;
        const isModified = targetControl.value && actionControl.value;
        return isModified && this.permissions.controls[idx].valid;
    }

    public getDataSource(idx: number): Chip[] {
        const targetControl = this.permissions.controls[idx].controls.target;
        switch (targetControl?.value) {
            case PeopleTargets.Companies:
                return this.companies;
            case PeopleTargets.Crafts:
                return this.crafts;
            case PeopleTargets.People:
                return this.people;
            case PeopleTargets.Tags:
                return this.tags;
        }
    }

    public checkProperties(idx: number): void {
        if (idx === this.permissions.controls.length - 1) {
            const targetControl = this.permissions.controls[idx].controls.target;
            const actionControl = this.permissions.controls[idx].controls.action;
            const validators = targetControl.value || actionControl.value ? [Validators.required] : [];
            targetControl.setValidators(validators);
            actionControl.setValidators(validators);
            targetControl.updateValueAndValidity();
            actionControl.updateValueAndValidity();
            this.markPermissionsAsDirty();
        }
    }

    public addPermissionRow(): void {
        (this.permissions as FormArray).controls.push(new FormGroup({
            target: new FormControl(null, []),
            action: new FormControl(null, []),
            values: new FormControl(null, []),
        }));
        this.markPermissionsAsDirty();
    }

    public removePermission(idx: number): void {
        (this.permissions as FormArray).controls.splice(idx, 1);
        this.markPermissionsAsDirty();
        this.addEmptyRowIfThereIsNone(idx);
    }

    private addEmptyRowIfThereIsNone(idx: number): void {
        if (idx === (this.permissions as FormArray).controls.length) {
            const emptyRow = this.permissions.controls.find(f => {
                const target = f.controls.target.value;
                const action = f.controls.action.value;
                const values = f.controls.values.value;
                return !(!!target || !!action || !!values?.length);
            });
            if (!emptyRow) this.addPermissionRow();
        }
    }

    public markPermissionsAsDirty(): void {
        (this.permissions as FormArray).updateValueAndValidity();
        this.markFormAsDirty();
    }

    public markFormAsDirty(): void {
        this.form.updateValueAndValidity();
        this.form.markAsDirty();
    }

    public isTagsDataSource(idx: number): boolean {
        return this.permissions.controls[idx].controls.target?.value === PeopleTargets.Tags;
    }

    async submit() {
        const formValues = this.form.getRawValue();
        const permissionFormData = formValues.permissions.filter(p => p.target);
        const data: Partial<Folder> = {
            id: this.folderId,
            parentId: this.parentFolderId,
            name: formValues?.name ?? '',
            permissions: this.buildPermissions(permissionFormData),
        };
        try {
            this.alertsService.setAppLoading(true);

            data.id ? await this.folderService.update(this, this.folderType, data)
                : await this.folderService.create(this, this.folderType, data);

            AnalyticsService.track(StObject.Folder, data.id ? StAction.Updated : StAction.Created, this.constructor.name);

            this.alertsService.sendSuccess({ messageKey: `folders.message.success.${data.id ? 'updated' : 'added'}` });
            this.closed.next(ModalActionType.submit);
        } catch (err) {
            this.alertsService.sendServiceError(err, data.id ? ServiceError.Update : ServiceError.Add, 'folders.folder');
        } finally {
            this.alertsService.setAppLoading(false);
        }
    }

    private buildPermissions(permissionFormData: Array<{ target: string, action: string, values: string[] }>): FolderPermission[] {
        return permissionFormData.map(p => ({
            name: p.action,
            people: {
                [p.target]: p.values,
            },
            grantedAction: p.action === 'generics.edit' ? this.editPermissionAction
                : p.action === 'generics.read' ? this.viewPermissionAction
                    : this.submitPermissionAction,
        }));
    }

    async handleModalClose(action: ModalActionType) {
        if (action === ModalActionType.cancel) {
            this.cancel();
        } else {
            this.closed.next(action);
        }
    }

    async cancel() {
        if (await this.pendingChangesGuard.canDeactivate(this)) {
            this.closed.next(ModalActionType.cancel);
        }
    }

    async modalOpen() {
        this.folderEditorModal = {
            isOpen: true,
            actions: {},
            header: {
                textKey: 'folders.add-folder.title',
                showSeparator: true,
                textAlignment: 'left',
            },
            fullScreen: false,
            content: true,
        };

        this.form = new FormGroup({
            id: new FormControl(this.folderId),
            name: new FormControl('', [Validators.required]),
            permissions: new FormArray([new FormGroup({
                target: new FormControl(null, []),
                action: new FormControl(null, []),
                values: new FormControl(null, []),
            })]),
        });

        await this.loadFolder();
    }

    modalClose() {
        this.folderEditorModal = null;
        this.closed.emit();
    }

    private async loadFolder() {
        this.alertsService.setAppLoading(true);
        const folder = this.folderId ? await this.folderService.getFolder(this, this.folderType, this.folderId) : null;

        this.form.patchValue({
            name: folder?.name ?? '',
        });

        const permissions = [new FormGroup({
            target: new FormControl(null, []),
            action: new FormControl(null, []),
            values: new FormControl(null, []),
        })];

        const folderPermissions = folder?.permissions?.flatMap(p => {
            return Object.keys(p.people).map(peopleTarget => {
                const action = p.grantedAction === this.editPermissionAction ? 'generics.edit'
                    : p.grantedAction === this.viewPermissionAction ? 'generics.read'
                        : 'generics.submit';
                return new FormGroup({
                    target: new FormControl(peopleTarget, [Validators.required]),
                    action: new FormControl(action, [Validators.required]),
                    values: new FormControl([...p.people[peopleTarget]], []),
                });
            });
        });

        this.form.controls.permissions = new FormArray(folderPermissions?.length ? folderPermissions : permissions);

        await this.getCommonData();
        this.form.markAsPristine();
    }

    private async getCommonData() {
        Utils.safeSubscribe(
            this,
            forkJoin([
                this.craftService.getAll(this),
                this.tagService.getAll(this),
                this.companyService.getAllOnAccount(this),
                this.personService.getPeople(this),
            ]),
        )
        .subscribe(res => {
            this.crafts = res[0];
            this.tags = res[1];
            this.companies = res[2];
            this.people = res[3]?.map(x => ({ id: x.id, name: x.fullName }));
            this.cdr.markForCheck();
            this.alertsService.setAppLoading(false);
        });
    }
}
